import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = { class: "flex-nav-prev" };
const _hoisted_3 = { class: "flex-nav-next" };
import { computed, onMounted, ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CarouselShared',
    props: {
        slideSelector: { type: String, default: '' },
        sliderSelector: { type: String, default: '' },
        includeDotButtons: { type: Boolean, default: true },
        includeDirectionButtons: { type: Boolean, default: false },
        buttonsCompacted: { type: Boolean, default: false },
        interval: { type: Number, default: 7000 },
        animation: { type: String, default: 'fade' },
        slideAnimationSpeed: { type: Number, default: 600 },
        amountOfSlides: { type: Number, default: 1 },
        maxItems: { type: Number, default: 1 },
        itemWidth: { type: Number, default: null },
        move: { type: Number, default: 1 },
        startAtIndex: { type: Number, default: 0 },
        isOneDirectionNav: { type: Boolean, default: false },
        oneDirectionButtonCustomClass: { type: String, default: '' },
        manualControlListSelector: { type: String, default: '' },
        manualArrowsSelector: { type: String, default: '' },
        forceAlwaysItemWidth: { type: Boolean, default: false },
        sliderWidthPercentage: { type: Number, default: 1000 },
        membershipType: { type: String, default: 'zumba' },
        autoscroll: { type: Boolean, default: true },
    },
    setup(__props) {
        _useCssVars(_ctx => ({
            "7b27aab2": (width.value),
            "d2d75da6": (sliderWidthRef.value),
            "799e24ed": (margin.value),
            "4de8a0d5": (slideSpeed.value)
        }));
        let activeSlide;
        let activeButton;
        let lastIndex;
        let currentSlide = 0;
        let timer;
        let initialMousePosition;
        let finalMousePosition;
        let slides = []; // eslint-disable-line no-undef
        const controlButtons = ref();
        const flexViewportRef = ref();
        const width = ref('0px');
        const margin = ref('0px');
        const slideSpeed = ref('600ms');
        const props = __props;
        const sliderWidth = computed(() => {
            if (props.sliderWidthPercentage !== 1000) {
                return `${(props.itemWidth / 100) * props.sliderWidthPercentage}px`;
            }
            return `${props.sliderWidthPercentage}%`;
        });
        const sliderWidthRef = ref(sliderWidth);
        const shouldAddDotButtons = computed(() => props.amountOfSlides > 1 && props.includeDotButtons);
        const shoudlAddDirectionButtons = computed(() => {
            return props.amountOfSlides > 1
                && props.includeDirectionButtons
                && props.amountOfSlides > props.maxItems;
        });
        const isOnDesktop = () => window.innerWidth > 768;
        const doGetDotButtonsIndexes = () => {
            const start = isOnDesktop() && props.maxItems ? props.maxItems : 1;
            const step = isOnDesktop() ? props.move : 1;
            const buttonsIndexes = Array.from({ length: Math.ceil(((props.amountOfSlides - start) / step)) + 1 }, (v, i) => i);
            return isOnDesktop() && props.amountOfSlides <= props.maxItems ? [] : buttonsIndexes;
        };
        const dotButtonsIndexesRef = ref(doGetDotButtonsIndexes());
        onMounted(() => {
            setWidth();
            setUpSlidesAnimationClass();
            setUpSlideAnimationIfNeeded();
            setUpSwipeEvents();
            setUpManualCustomControlsIfNeeded();
            if (lastIndex !== 0 && props.autoscroll) {
                timer = setInterval(() => goToNextSlide(), props.interval);
            }
            goToSlide(props.startAtIndex);
        });
        const getMinWidth = () => {
            if (props.itemWidth) {
                return props.forceAlwaysItemWidth
                    ? props.itemWidth
                    : Math.min(props.itemWidth, flexViewportRef.value?.offsetWidth ?? 0);
            }
            return flexViewportRef.value?.offsetWidth;
        };
        const setWidth = () => width.value = `${getMinWidth()}px`;
        const setMargin = () => {
            const step = isOnDesktop() ? props.move : 1;
            margin.value = `-${(getMinWidth()) * (currentSlide * step)}px`;
        };
        const setLastIndex = () => lastIndex = Math.max(0, dotButtonsIndexesRef.value.length - 1);
        const setUpManualCustomControlsIfNeeded = () => {
            if (props.manualControlListSelector !== '') {
                const controlItems = Array.from(document.querySelector(props.manualControlListSelector)?.children ?? []);
                controlItems.forEach((controlItem, index) => {
                    controlItem.addEventListener('click', function () { handleClick(index); }, false);
                });
            }
            if (props.manualArrowsSelector !== '') {
                const [left, right] = Array.from(document.querySelectorAll(props.manualArrowsSelector));
                if (left)
                    left.addEventListener('click', handlePreviousClick, false);
                if (right)
                    right.addEventListener('click', handleNextClick, false);
            }
        };
        const setUpSlidesAnimationClass = () => {
            slides = document.querySelectorAll(props.slideSelector);
            slides.forEach(slide => slide.classList.add(props.animation));
            setLastIndex();
        };
        const setUpSwipeEvents = () => {
            doSetUpSwipeEvents(props.sliderSelector);
            if (shoudlAddDirectionButtons.value) {
                doSetUpSwipeEvents('.flex-direction-nav');
            }
        };
        const doSetUpSwipeEvents = (querySelector) => {
            const slider = document.querySelector(querySelector);
            slider?.addEventListener('touchstart', handleTouchStart, { passive: true });
            slider?.addEventListener('touchmove', handleTouchMove, { passive: false });
            slider?.addEventListener('touchend', handleTouchEnd, { passive: true });
        };
        const handleTouchStart = (e) => initialMousePosition = e.targetTouches[0];
        const handleTouchMove = (e) => {
            finalMousePosition = e.targetTouches[0];
            const moveX = Math.abs(finalMousePosition.pageX - initialMousePosition.pageX);
            const moveY = Math.abs(finalMousePosition.pageY - initialMousePosition.pageY);
            // Prevent horizontal scrolling when swiping sideways
            if (moveX > moveY) {
                e.preventDefault();
            }
        };
        const handleTouchEnd = () => {
            if (!finalMousePosition) {
                tearDownPositions();
                return;
            }
            const isLeftMove = finalMousePosition.pageX > initialMousePosition.pageX;
            // Avoid moving the slider on minimal swipes
            const isMinimalSwipe = Math.abs(finalMousePosition.pageX - initialMousePosition.pageX) < initialMousePosition.pageX * 0.1;
            if (isMinimalSwipe) {
                tearDownPositions();
                return;
            }
            if (isLeftMove) {
                handlePreviousClick();
            }
            else {
                handleNextClick();
            }
            tearDownPositions();
        };
        const tearDownPositions = () => {
            finalMousePosition = null;
            initialMousePosition = null;
        };
        const setUpSlideAnimationIfNeeded = () => {
            if (props.animation === 'slide') {
                setWidth();
                slideSpeed.value = `${props.slideAnimationSpeed}ms`;
                window.addEventListener("resize", handleResize);
                document.querySelector(props.sliderSelector)?.classList.add('slider-active');
            }
        };
        const handleResize = () => {
            setWidth();
            setMargin();
            setDotButtonsIndexes();
            setLastIndex();
            goToSlide(currentSlide);
        };
        const setDotButtonsIndexes = () => dotButtonsIndexesRef.value = doGetDotButtonsIndexes();
        const handleNextClick = () => {
            resetTimer();
            goToNextSlide();
        };
        const handlePreviousClick = () => {
            resetTimer();
            goToPreviousSlide();
        };
        const resetTimer = () => {
            clearInterval(timer);
            timer = setInterval(() => goToNextSlide(), props.interval);
        };
        const handleClick = (index) => {
            resetTimer();
            goToSlide(index);
        };
        const goToNextSlide = () => {
            if (currentSlide === lastIndex) {
                goToSlide(0);
            }
            else {
                goToSlide(currentSlide + 1);
            }
        };
        const goToPreviousSlide = () => {
            if (currentSlide === 0) {
                goToSlide(lastIndex);
            }
            else {
                goToSlide(currentSlide - 1);
            }
        };
        const goToSlide = (index) => {
            currentSlide = index;
            const slide = slides[index];
            const button = props.manualControlListSelector !== ''
                ? document.querySelector(props.manualControlListSelector)?.children[index]
                : controlButtons.value?.children[index]?.firstElementChild;
            toggleSlideClasses(slide);
            toggleButtonClasses(button);
        };
        const toggleButtonClasses = (button) => {
            activeButton?.classList.remove('flex-active-' + props.membershipType);
            button?.classList.add('flex-active-' + props.membershipType);
            activeButton = button;
        };
        const toggleSlideClasses = (slide) => {
            if (props.animation === 'fade') {
                activeSlide?.classList.remove(`${props.animation}-active`);
                slide?.classList.add(`${props.animation}-active`);
                activeSlide = slide;
            }
            else {
                setMargin();
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (props.animation === 'slide')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        ref_key: "flexViewportRef",
                        ref: flexViewportRef,
                        class: "flex-viewport",
                        style: { "overflow": "hidden", "position": "relative" }
                    }, [
                        _renderSlot(_ctx.$slots, "default")
                    ], 512))
                    : _renderSlot(_ctx.$slots, "default", { key: 1 }),
                (shouldAddDotButtons.value)
                    ? (_openBlock(), _createElementBlock("ol", {
                        key: 2,
                        ref_key: "controlButtons",
                        ref: controlButtons,
                        class: _normalizeClass({
                            'flex-control-nav': true,
                            'flex-control-paging': true,
                            'flex-control-compacted': props.buttonsCompacted,
                        })
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dotButtonsIndexesRef.value, (slidesButtonNumber) => {
                            return (_openBlock(), _createElementBlock("li", { key: slidesButtonNumber }, [
                                _createElementVNode("a", {
                                    class: _normalizeClass({
                                        [`link-${props.membershipType}`]: true,
                                    }),
                                    onClick: ($event) => (handleClick(slidesButtonNumber))
                                }, _toDisplayString(slidesButtonNumber + 1), 11, _hoisted_1)
                            ]));
                        }), 128))
                    ], 2))
                    : _createCommentVNode("", true),
                (shoudlAddDirectionButtons.value)
                    ? (_openBlock(), _createElementBlock("ul", {
                        key: 3,
                        class: _normalizeClass(["flex-direction-nav", { 'one-way-direction': props.isOneDirectionNav }])
                    }, [
                        _withDirectives(_createElementVNode("li", _hoisted_2, [
                            _createElementVNode("a", {
                                class: "flex-prev",
                                onClick: _cache[0] || (_cache[0] = ($event) => (handlePreviousClick()))
                            }, " Previous ")
                        ], 512), [
                            [_vShow, !props.isOneDirectionNav]
                        ]),
                        _createElementVNode("li", _hoisted_3, [
                            (props.oneDirectionButtonCustomClass === '')
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    class: "flex-next",
                                    onClick: _cache[1] || (_cache[1] = ($event) => (handleNextClick()))
                                }, " Next "))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: "flex-next",
                                    onClick: _cache[2] || (_cache[2] = ($event) => (handleNextClick()))
                                }, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass({ [props.oneDirectionButtonCustomClass]: true })
                                    }, null, 2)
                                ]))
                        ])
                    ], 2))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
